import React, { useState } from "react"
import { graphql } from "gatsby"
// import moment from "moment"
import Layout from "../../components/layout"
// import Markdown from "react-markdown"
// import { get } from "lodash"
// import { withPrefix } from "../../utils"
import { Link } from "gatsby"
import { PageBreadcrumb } from "../../components/shared"
import {
  SectionRatings,
  SectionRealEstate,
  // SectionPropertyTax,
  SectionIncome,
  SectionEducation,
  SectionEmployment,
} from "../../components/county"

import SectionTitle from "../../components/shared/SectionTitle"
// import { BlogSidebar } from "../../components/blogs"
// import { GatsbyImage } from "gatsby-plugin-image"
import FeatherIcon from "feather-icons-react"

import { Container, Row, Col, Card, CardBody } from "reactstrap"

import health from "../../assets/images/hero/health.svg"
import termLife from "../../assets/images/hero/term-life.svg"
import familyHealth from "../../assets/images/hero/family-health.svg"

export const query = graphql`
  query MarketInfoQuery($slug: String!) {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        robots
        metaDescription
        metaTitle
        keywords
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiMarket(slug: { eq: $slug }) {
      slug
      summary
      name
      seo {
        metaDescription
        metaTitle
        robots
        keywords
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiZipInfo(filter: { state: { eq: $slug } }) {
      totalCount
      edges {
        node {
          id
          bus_ratio
          county
          fips_code
          four_bedroom_rent
          lat
          lng
          median_income
          one_bedroom_rent
          oth_ratio
          res_ratio
          state
          studio_rent
          three_bedroom_rent
          two_bedroom_rent
          zip
        }
      }
    }
  }
`

const Market = ({ data }) => {
  const { strapiGlobal, strapiMarket, allStrapiZipInfo } = data

  // TODO: rpovide distinct counties and links to those county pages.
  return (
    <Layout seo={strapiMarket.seo} strapiGlobal={strapiGlobal}>
      <PageBreadcrumb
        page={{
          source: "markets",
          showBreadcrumb: true,
        }}
      >
        <Container>
          <Row className="mt-5 pt-5 justify-content-center">
            <Col lg={12}>
              <div className="title-heading text-center">
                <h1 className="heading title-dark text-white mb-3">
                  {strapiMarket.name}
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </PageBreadcrumb>

      <section className="section">
        <SectionTitle title={`Market Insights - ${strapiMarket.name}`} />
        <section>
          <Container>
            <Row className="align-items-center">
              <p className=" text-muted">{strapiMarket.summary}</p>
            </Row>
          </Container>
        </section>
        {/*   <SectionRatings zip={countyData.zip} county={countyData} />
        <SectionIncome zip={countyData.zip} />
        <SectionEducation zip={countyData.zip} />
        <SectionEmployment zip={countyData.zip} />
        <SectionRealEstate
          county={countyData.county}
          state={countyData.state}
          allCountyData={allCountyData}
        />
        <SectionPropertyTax
          county={countyData.county}
          state={countyData.state}
        /> */}
      </section>
    </Layout>
  )
}

export default Market
